.container {
  display: flex;
  height: 100vh;

  .banner {
    width: 800px;
    // background: linear-gradient(163.85deg, #1d2129 0%, #00308f 100%);
  }

  .content {
    flex: 1;
    position: relative;
    padding-bottom: 40px;
  }

  .footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.logo {
  position: fixed;
  top: 24px;
  left: 22px;
  display: inline-flex;
  align-items: center;
  z-index: 1;

  &-text {
    margin-left: 4px;
    margin-right: 4px;
    font-size: 20px;
    color: var(--color-fill-1);
  }
}

.banner {
  // display: flex;
  // justify-content: center;
  // align-items: center;

  &-inner {
    height: 100%;
    // flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.content {
  display: flex;
  width: calc(100vw - 800px);
  justify-content: center;
  align-items: center;
  position: relative;
  .content-logo {
    position: absolute;
    top: 30px;
    right: 30px;
    display: flex;
    justify-content: flex-end;
  }
}

.carousel {
  height: 100%;

  &-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-fill-1);
  }

  &-sub-title {
    margin-top: 8px;
    font-size: 14px;
    line-height: 22px;
    color: var(--color-text-3);
  }

  &-image {
    margin-top: 30px;
    width: 320px;
  }
}

.login-form {
  &-wrapper {
    width: 320px;
  }
  // &-input {
  //   border-bottom: 1px solid #1d4e84;
  //   :global {
  //     .arco-input-inner-wrapper {
  //       background-color: transparent !important;
  //       border: unset;
  //       &-focus,
  //       &:hover {
  //         border: unset !important;
  //         box-shadow: unset !important;
  //       }
  //     }
  //     .arco-input-group-addafter {
  //       background-color: transparent !important;
  //       // border-left: unset;
  //     }
  //   }
  // }

  &-title {
    font-size: 28px;
    font-weight: 800;
    text-align: center;
    color: var(--color-text-1);
    line-height: 32px;
    // color: rgb(29, 78, 132);
  }

  &-sub-title {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-top: 20px;
    color: var(--color-text-3);
  }

  &-error-msg {
    height: 32px;
    line-height: 32px;
    color: rgb(var(--red-6));
  }

  &-password-actions {
    display: flex;
    justify-content: space-between;
  }

  &-register-btn {
    font-weight: bold;
    // color: #1d4e84 !important;
  }
}
