.expert-card {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
  border-radius: 10px 10px;
  // min-width: 400px;
  width: 100%;
  // height: 330px;
  margin-bottom: 20px;

  &__desc {
    box-sizing: border-box;
    padding: 0 10px;
    margin-top: 10px;
    height: 75px;
    line-height: 25px;
  }
  &__action {
    display: flex;
    // justify-content: space-around;
    .iconWithText {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 0px;
      font-size: 13px;
      box-sizing: border-box;
      // box-shadow: 0 0 10px 0 red;
      &:hover {
        /* 禁用触摸设备上的 hover 效果 */
        @media (hover: none) and (pointer: coarse) {
          box-shadow: unset;
          border-radius: unset;
        }
        border-radius: 5px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
        cursor: pointer;
      }
    }
  }
}

.iconWithText {
  display: flex;
  align-items: center;
  .icon {
    display: flex;
    align-items: center;
    img {
      display: flex;
    }
  }
  .text {
    margin-left: 4px;
  }
}
.expert-userinfo-card {
  &--mini {
    // min-width: 280px;
  }
  &--single {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding-bottom: 10px;
  }
  // min-width: 400px;
  &__userinfo {
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    &--avatar {
    }
    &--content {
      margin-left: 10px;
      width: calc(100% - 110px);
      .nickname {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name {
          font-size: 20px;
          color: var(--color-text-1);
          font-weight: bold;
          width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .professional {
          font-size: 14px;
          line-height: 20px;
          display: flex;
          align-items: center;
          width: calc(100% - 240px);
          justify-content: flex-end;
          .text {
            margin-left: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .professional {
        margin-top: 10px;
        height: 40px;
      }
      .meta {
        font-size: 12px;
        display: flex;
        align-items: center;
        margin-top: 5px;
      }
      .address {
        color: var(--color-text-1);
        margin-top: 10px;
      }
    }
  }
  &__major {
    box-sizing: border-box;
    padding: 0 10px;
    display: flex;
    // align-items: center;
    margin-top: 10px;
    &--title {
      font-size: 14px;
      color: var(--color-text-1);
      font-weight: 500;
      width: 75%;
      height: 25px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &--meta {
      margin-left: 5%;
      display: flex;
      justify-content: flex-end;
      width: 20%;
    }
  }
}

.expert-userinfo-mini-card {
  &--single {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding-bottom: 10px;
  }
  // min-width: 280px;
  &__userinfo {
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    &--avatar {
    }
    &--content {
      margin-left: 10px;
      width: calc(100% - 80px);
      .nickname {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name {
          font-size: 20px;
          color: var(--color-text-1);
          font-weight: bold;
          width: 180px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .professional {
          font-size: 14px;
          line-height: 20px;
          display: flex;
          align-items: center;
          width: calc(100% - 200px);
          justify-content: flex-end;
          .text {
            margin-left: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .professional {
        margin-top: 10px;
        height: 40px;
      }
      .meta {
        font-size: 14px;
        display: flex;
        align-items: center;
        margin-top: 10px;
      }
      .address {
        color: var(--color-text-1);
        margin-top: 20px;
      }
    }
  }
  &__major {
    box-sizing: border-box;
    padding: 0 10px;
    display: flex;
    // align-items: center;
    margin-top: 10px;
    &--title {
      font-size: 14px;
      color: var(--color-text-1);
      font-weight: 500;
      width: 75%;
      height: 25px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &--meta {
      margin-left: 5%;
      display: flex;
      justify-content: flex-end;
      width: 20%;
    }
  }
  &__professional {
    box-sizing: border-box;
    padding: 0 10px;
    display: flex;
    line-height: 20px;
    height: 20px;
    .text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 240px;
    }
    // align-items: center;
  }
}
