@import 'nprogress/nprogress.css';
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  background-color: var(--color-bg-1);
}

.two-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.chart-wrapper {
  .bizcharts-tooltip {
    background: linear-gradient(
      304.17deg,
      rgb(253 254 255 / 60%) -6.04%,
      rgb(244 247 252 / 60%) 85.2%
    ) !important;
    border-radius: 6px;
    backdrop-filter: blur(10px);
    padding: 8px !important;
    width: 180px !important;
    opacity: 1 !important;
  }
}

body[arco-theme='dark'] {
  .chart-wrapper {
    .bizcharts-tooltip {
      background: linear-gradient(
        304.17deg,
        rgba(90, 92, 95, 60%) -6.04%,
        rgba(87, 87, 87, 60%) 85.2%
      ) !important;
      backdrop-filter: blur(10px);
      border-radius: 6px;
      box-shadow: none !important;
    }
  }
}
.arco-table-border-cell:not(.arco-table-border) .arco-table-td:first-child {
  border-left: 1px solid var(--color-neutral-3) !important;
}

#root {
  .app__header {
    background-color: #fff;
    display: none;
    &--header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      &--logo {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        img {
          width: 150px;
        }
      }
      &--action {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-right: 5px;
        font-size: 12px;
      }
    }
  }
  .pc__header {
    display: block;
  }

  @media (max-width: 992px) {
    .pc__header {
      display: none;
    }
    .app__header {
      display: block;
    }
  }

  .steps {
    padding: 10px 5px;
    box-sizing: border-box;
    background: #fff;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    .step {
      width: 25%;
      text-align: center;
      display: flex;
      justify-content: space-around;
      align-items: center;
      &--container {
        display: inline-block;
        z-index: 2;
        position: relative;
        &--icon {
          text-align: center;
          background: var(--color-bg-2);
        }
        &--title {
          text-align: center;
          font-size: 12px;
          color: var(--color-text-2);
          margin-top: 5px;
        }
      }
    }
  }
}

.custom-modal {
  width: 80% !important;
  @media (max-width: 576px) {
    width: 100% !important;
  }
}
.arco-modal-content {
  padding: 10px !important;
}
